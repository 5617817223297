<!-- 考试列表 -->
<template>
  <div class="text-list-war">
    <!-- <div class="head">
      成绩列表
    </div> -->
    <van-cell-group>
      <van-cell 
        v-for="item in applyList" 
        :key="item.id" 
        :title="item.periodName" 
        is-link 
        :value="item.artMajorName + item.levelName"
        @click="cellClick(item)"
      />
    </van-cell-group>
    <div v-if="applyList.length <= 0" class="empty-text">
      暂无成绩！
    </div>

  </div>
</template>
<script>
import { Cell ,CellGroup} from 'vant';
import { queryScore } from '@/api'
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      applyList: [],
      idCard: ''
    }
  },
  created() {
    this.idCard = this.$route.query.idCard
    this.getScoreList()
  },  
  methods: {
    getScoreList() {
      const params = {
          idCard: this.idCard
        }
        this.saveLoading = true
        queryScore(params).then((res) => {
          this.saveLoading = false
          this.applyList = res.resultData || []
        }).catch(() => {
          this.saveLoading = false
        })
    },
    cellClick(data) {
      const jsonStr = JSON.stringify(data)
      this.$router.push({path: '/scoreCheck', query: {score: jsonStr}}) 
    }
  }
}
</script>

<style lang="less" scoped>
.text-list-war {
  height: 100%;
  padding: 10px;
  position: relative;
  .head {
    font-size: 25px;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.empty-text {
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  color: #ccc;
}
</style>